import React from "react";
import loadingGif from "../assets/images/loading-gif.gif";

const LoadingScreen = ({ isLoading = true }) => {
  if (isLoading) {
    return (
      <div className="loading-container">
        <img alt="" className="loading-container__image" src={loadingGif} />
        <div className="loading-container__text">Loading...</div>
      </div>
    );
  } else return <></>;
};

export default LoadingScreen;
