import React from "react";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import ContactInitial from "../Common/ContactInitial";
import MakeCallButton from "../Common/MakeCallButton";
import Call from "../assets/images/log_a_call.svg";
import Note from "../assets/images/log_a_note.svg";
import loadingGif from "../../../Components/CallModule/CallButton/assets/images/loading-gif.gif";

class LeadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: "",
      commentTitle: ""
    };
  }

  render() {
    const { currentLead, selectCurrentLead, currentLeadIndex } = this.props;
    return (
      typeof currentLead !== "undefined" &&
      Object.values(currentLead.leadDetails).length > 0 && (
        <div className="card lead-details">
          <div className="card-header rx-card-header py-4">
            <div className="d-flex">
              <div className="me-3">
                <ContactInitial
                  leadName={currentLead.leadDetails.name}
                  spanPadding="p-4"
                />
              </div>
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div>
                  <div className="name">{currentLead.leadDetails.name}</div>
                  <div className="address">
                    {currentLead.leadDetails.address}
                  </div>
                </div>
                <MakeCallButton
                  leadNumber={currentLead.leadDetails.number}
                  leadName={currentLead.leadDetails.name}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <p className="card-title mb-5">
              {currentLead.leadDetails.description}
            </p>
            <p className="card-text mb-1">
              <strong>Hours: </strong>
              <span>
                Open{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.start
                }{" "}
                - Closes{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.end
                }{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.tz
                }
              </span>
            </p>
          </div>
        </div>
      )
    );
  }
}

export default LeadDetails;
