import React from "react";
import { useState } from "react";
import { useContext } from "react";
import StatisticAPIContext from "./contexts/StatisticAPIContext";
import { getLastNthDay } from "./helpers";

const stats_filters = [
  { value: 0, displayText: "Today" },
  { value: 7, displayText: "Last 7 days" },
  { value: 15, displayText: "Last 15 days" },
  { value: 30, displayText: "Last 30 days" },
];

const DataFilterBar = () => {
  const { onGetData } = useContext(StatisticAPIContext);

  const [value, setValue] = useState(0);

  const onHandleDataChange = async (event) => {
    const dateOffset = event.target.value;
    const end_date = getLastNthDay(0);
    const start_date = getLastNthDay(dateOffset);

    await onGetData({ start_date, end_date });

    setValue(dateOffset);
  };

  return (
    <div className="filter-bar-container">
      <span>Statistics</span>
      <select
        onChange={onHandleDataChange}
        className="select-box"
        value={value}
      >
        {stats_filters.map((filter) => (
          <option key={filter.value} value={filter.value}>
            {filter.displayText}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DataFilterBar;
