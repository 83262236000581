import React from "react";
import Lead from "./Lead/Lead";
import Upload from "./Upload/Upload";
import LeadDetails from "./LeadDetails/LeadDetails";
import { fetchFeedForLead, addFeedForLead } from "./assets/helpers/helper";
import Leads from "./assets/images/leads.svg";
import Uploads from "./assets/images/uploads.svg";
import ChartIcon from "./assets/images/chart.png";
import Logo from "../Task/assets/images/logo.png";
import "./style.css";
import { StatisticChartContainer } from "./Charts/StatisticalCharts";
import { io } from "socket.io-client";

class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLead: { leadDetails: {}, comments: [] },
      currentLeadIndex: null,
      selectedComponetOrder: 1,
      isFetchingLeadComments: false
    };
    this.socket;
  }

  componentDidMount() {
    const { REACT_APP_SOKET_IO_BASE_URL } = process.env;
    this.socket = io.connect(REACT_APP_SOKET_IO_BASE_URL);
    this.socket.on("chat", (chat) => {
      if (chat.refreshNotesAndActivity) {
        this.fetchLeads(chat.phoneNumber);
      }
    });
  }

  selectCurrentLead = async (leadIndex, filteredLeads) => {
    this.setState({
      ...this.state,
      currentLeadIndex: leadIndex,
      currentLead: {
        ...this.state.currentLead,
        leadDetails: filteredLeads[leadIndex]
      }
    });
  };

  alterComponentSelection = (orderIndex) => {
    this.setState({ ...this.state, selectedComponetOrder: orderIndex });
  };

  render() {
    const {
      currentLead,
      currentLeadIndex,
      selectedComponetOrder,
      isFetchingLeadComments
    } = this.state;
    const { user, leads, fetchLeadsOfLoggedinUser, sdfcDetails } = this.props;
    return (
      <div>
        <div className="welcomeDiv d-flex flex-reverse justify-content-between mb-5 headerContainer">
          <img src={Logo} alt="Regalix" className="headerLogo" />
          <h5>
            Welcome <strong>{user.name}</strong>
          </h5>
        </div>
        <div className="col-md-12 d-flex divHeight100 rx-padding-2-rem">
          <div className="col-md-2 divHeight100 me-2 hand-pointer w-5 black-background">
            <div
              onClick={() => this.alterComponentSelection(1)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 1 && "activeTab"
              }`}
            >
              <img src={Leads} alt="leads" className="leftMenuIcons" />
            </div>
            <div
              onClick={() => this.alterComponentSelection(2)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 2 && "activeTab"
              }`}
            >
              <img src={Uploads} alt="Uploads" className="leftMenuIcons" />
            </div>
            <div
              onClick={() => this.alterComponentSelection(3)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 3 && "activeTab"
              }`}
            >
              <img
                src={ChartIcon}
                alt="Charts"
                className="leftMenuIcons leftMenuIcons--chart-icon"
              />
            </div>
          </div>
          {selectedComponetOrder === 1 && leads.length > 0 && (
            <>
              <div className="col-md-3 divHeight100 me-2 hand-pointer">
                <Lead
                  leads={leads}
                  selectCurrentLead={this.selectCurrentLead}
                  currentLeadIndex={currentLeadIndex}
                />
              </div>

              <div className="w-100 rx-border-color rx-lead-details p-3">
                <LeadDetails
                  currentLead={currentLead}
                  currentLeadIndex={currentLeadIndex}
                  selectCurrentLead={this.selectCurrentLead}
                  sdfcDetails={sdfcDetails}
                  isFetchingLeadComments={isFetchingLeadComments}
                />
              </div>
            </>
          )}
          {selectedComponetOrder === 2 && (
            <Upload
              user={user}
              fetchLeadsOfLoggedinUser={fetchLeadsOfLoggedinUser}
              alterComponentSelection={this.alterComponentSelection}
            />
          )}

          {selectedComponetOrder === 3 && <StatisticChartContainer />}
        </div>
      </div>
    );
  }
}

export default Task;
