import React from "react";
import callattend from "../assets/images/callicon.svg";
import { formatPhoneNumber } from "../assets/helpers/helper";

const MakeCallButton = ({ leadNumber, leadName, shouldHideContactNumber }) => {
  return (
    <div className="d-flex align-items-center hand-pointer">
      {leadNumber && !shouldHideContactNumber && (
        <div className="me-2">{formatPhoneNumber(leadNumber)}</div>
      )}
      <img
        alt=""
        className="rounded-circle w-100 makeCallBtn"
        src={`${callattend}`}
        onClick={() => {
          if (leadNumber) {
            window.makeOutBoundCall(leadNumber, leadName);
          }
        }}
      />
    </div>
  );
};

export default MakeCallButton;
