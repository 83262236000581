/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  barChartoptions,
  pieChartoptions,
  defaultBarChartData,
} from "../../assets/constant";
import {
  getBarChartData,
  getPieChartData,
  getLastNthDay,
  requestHeader,
} from "../helpers";

export const useHandleStatisticsFetching = () => {
  const [barChartdata, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [pieChartdata, setPieChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const onGetData = async (params = {}) => {
    try {
      setIsLoading(true);

      const { tenant_id, subtenant_id, number } = JSON.parse(
        localStorage.getItem("user")
      );

      const config = requestHeader("/analytics/calls", "POST", {
        tenant_id,
        subtenant_id,
        phone_number: number,
        ...params,
      });

      const response = await axios(config);
      const displayedData = getBarChartData(response.data);

      setBarChartData(displayedData);

      const pieChartData = getPieChartData(response.data);
      setPieChartData(pieChartData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const start_date = getLastNthDay(0);

      await onGetData({ start_date, end_date: start_date }); // default date is today
    };
    fetchInitialData();
  }, []);

  return {
    barChartdata,
    barChartoptions,
    pieChartdata,
    pieChartoptions,
    onGetData,
    isLoading,
    setIsLoading,
  };
};
