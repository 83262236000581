import axios from "axios";
import { urls } from "./constant";
const { REACT_APP_API_BASE_URL, REACT_APP_MSAL_CLIENT_ID, REACT_APP_MSAL_TENANT_ID, REACT_APP_REDIRECT_URI } = process.env;


const requestHeader = (apiEndPoint, method, formData = {}) => {
  var config = {
    method: method,
    url: `${REACT_APP_API_BASE_URL}${apiEndPoint}`,
    headers: {
      "Content-Type": "application/json"
    },
    data: formData
  };

  return config;
};

export const getLoggedinUser = (email_id) => {
  var config = requestHeader(urls.loggedinUser.url, urls.loggedinUser.method, {
    email_id
  });
  return axios(config);
};

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${REACT_APP_MSAL_TENANT_ID}`,
    redirectUri: REACT_APP_REDIRECT_URI,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};