import React from "react";
import ContactInitial from "../Common/ContactInitial";
import MakeCallButton from "../Common/MakeCallButton";
import chevronRight from "../assets/images/chevron-right.svg";
import searchicon from "../assets/images/searchicon.svg";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class Lead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allLeads: props.leads,
      filteredLeads: props.leads,
      searchText: "",
      campaignLabels: []
    };
  }

  componentDidMount() {
    const { allLeads } = this.state;
    const tempCampaignLeads = [];
    allLeads.map((element) => {
      if (!tempCampaignLeads.includes(element.campaign)) {
        tempCampaignLeads.push(element.campaign);
      }
    });
    this.setState({ ...this.state, campaignLabels: [...tempCampaignLeads] });
  }

  filterLeadsByCampaign = (e) => {
    const { allLeads } = this.state;
    let campaignLeads = [];
    if (e.target.value === "") {
      campaignLeads = allLeads;
    } else {
      campaignLeads = allLeads.filter(
        (element) => element.campaign === e.target.value
      );
    }

    this.setState({ ...this.state, filteredLeads: [...campaignLeads] });
  };

  render() {
    const { selectCurrentLead, currentLeadIndex } = this.props;
    const { filteredLeads, searchText, campaignLabels } = this.state;
    return (
      <div className="card mx-2 no-border">
        <div className="searchboxsec">
          <select
            name="campaignName"
            id="campaignName"
            className="form form-control mb-2"
            onChange={(e) => this.filterLeadsByCampaign(e)}
          >
            <option value="">Select Campaign</option>
            {campaignLabels.map((element, index) => (
              <option value={element} key={index}>
                {element}
              </option>
            ))}
          </select>
        </div>
        {filteredLeads.length === 0 && searchText && (
          <p className="text-center">No results found.</p>
        )}
        {filteredLeads.length > 0 && (
          <ul className="list-group list-group-flush">
            {filteredLeads.map((lead, index) => (
              <li
                className={`list-group-item ${
                  currentLeadIndex === index
                    ? "activeLaedCart"
                    : "inactiveLaedCart"
                }`}
                key={index}
              >
                <div className="col-md-12 d-flex">
                  <div className="me-2 col-md-2 d-flex align-items-center">
                    <ContactInitial leadName={lead.name} spanPadding="p-2" />
                  </div>
                  <div
                    className="col-md-8 user-select hand-pointer"
                    onClick={() => selectCurrentLead(index, filteredLeads)}
                  >
                    <div className="name">{lead.name}</div>
                    <div className="address">{lead.address}</div>
                    <div className="hours">
                      Hours:{" "}
                      {lead.communication_preferences.call.preferred_time.start}{" "}
                      -{lead.communication_preferences.call.preferred_time.end}{" "}
                      {lead.communication_preferences.call.preferred_time.tz}
                    </div>
                  </div>
                  {currentLeadIndex === index ? (
                    <img className="rounded-circle w-100" src={chevronRight} />
                  ) : (
                    <MakeCallButton
                      leadName={lead.name}
                      leadNumber={lead.number}
                      shouldHideContactNumber={true}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Lead;
