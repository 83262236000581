import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./Components/Login/Login";
import { getLoggedinUserDetails } from "./Components/CallModule/Dialer/assets/library/api";

const App = ({ token }) => {
  const [user, setUser] = useState({
    id: "",
    name: "",
    number: "",
    identity: "",
    tenant_id: "",
    subtenant_id: ""
  });

  return (
    <div className="App">
      <Login user={user} />
    </div>
  );
};

export default App;
