import React from "react";
import { Button, Popover, Overlay } from "react-bootstrap";
import { searchPeople } from "../assets/library/api";
import { countTheCallsActive } from "../assets/library/helper";
import searchicon from "../assets/images/searchicon.svg";
import smallcallicon from "../assets/images/smallcallicon.svg";
import calloptionicon from "../assets/images/calloptionicon.svg";
import smallmessageicon from "../assets/images/smallmessageicon.svg";
import loadingGif from "../../../CallButton/assets/images/loading-gif.gif";
import { displayContactInitial } from "../assets/library/helper";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiFetching: false,
      contactList: [],
      timer: null,
      target: null,
      shouldDisplayPopup: false,
      currentContactElement: [],
      contactListActionType: null,
      processingAddOrTransferParticipant: false
    };
    this.activeCallCount = 0;
  }

  async componentDidMount() {
    const { callDetails } = this.props;
    this.startFetchingContact();
    this.activeCallCount = await countTheCallsActive(callDetails);
  }

  searchContact = (e) => {
    const { value } = e.target; // TODO: change later for actual implementation once NCAMEO team alter the API

    const { timerWillStart } = this.state;
    clearTimeout(timerWillStart);
    const timer = setTimeout(async () => {
      await this.startFetchingContact(value);
    }, 2000);
    this.setState({ ...this.state, timerWillStart: timer });
  };

  startFetchingContact = (value = "") => {
    const { user } = this.props;
    this.setState({ ...this.state, isApiFetching: true });
    const searchContactResponse = searchPeople(
      user.tenant_id,
      user.subtenant_id,
      user.identity,
      value.replace(/\s\s+/g, " ").trim(),
      user.email_id
    );
    searchContactResponse.then((contact) => {
      this.setState({
        ...this.state,
        contactList: contact.data,
        isApiFetching: false
      });
    });
  };

  prepareItemAction = async (e, contactElement, actionType) => {
    this.setState({ ...this.state, processingAddOrTransferParticipant: true });
    const { makeOutBoundCall, alterButtonDiableProperty } = this.props;
    if (contactElement.numbers.length <= 1) {
      alterButtonDiableProperty(true);
      if (actionType === "call" || actionType === "") {
        makeOutBoundCall(contactElement.numbers[0].number, contactElement.name);
      } else if (actionType === "addParticipant") {
        window.addConferenceParticipantInit(
          contactElement.numbers[0].number,
          contactElement.name
        );
      } else if (actionType === "transfer") {
        window.processCallTransfer(
          contactElement.numbers[0].number,
          contactElement.name
        );
      } else {
        window.startCreateatingConversation(
          contactElement.numbers[0].number,
          contactElement.name,
          true
        );
      }
    } else {
      this.setState(
        {
          ...this.state,
          target: e.target,
          currentContactElement: contactElement,
          contactListActionType: actionType
        },
        () =>
          this.setState({
            ...this.state,
            shouldDisplayPopup: !this.state.shouldDisplayPopup
          })
      );
    }
  };

  popover = () => {
    const { makeOutBoundCall, shouldDisableButton, alterButtonDiableProperty } =
      this.props;
    const { currentContactElement, contactListActionType } = this.state;

    return (
      <Popover id="popover-basic">
        <Popover.Body>
          <ul>
            {currentContactElement.numbers.map((element, index) => (
              <li
                key={index}
                onClick={() => {
                  alterButtonDiableProperty(true);
                  return `${
                    contactListActionType === "call" ||
                    contactListActionType === "" ||
                    contactListActionType === "addParticipant"
                      ? this.activeCallCount === 0
                        ? makeOutBoundCall(
                            element.number,
                            currentContactElement.name
                          )
                        : window.addConferenceParticipantInit(
                            element.number,
                            currentContactElement.name
                          )
                      : contactListActionType === "transfer"
                      ? window.processCallTransfer(
                          element.number,
                          currentContactElement.name
                        )
                      : window.startCreateatingConversation(
                          element.number,
                          currentContactElement.name,
                          true
                        )
                  }`;
                }}
              >
                <img
                  alt=""
                  className="calldropicon"
                  src={`${
                    shouldDisableButton
                      ? `${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`
                      : `${REACT_APP_STATIC_ASSETS_BASE_URL}${calloptionicon}`
                  }`}
                />{" "}
                {element.number}
              </li>
            ))}
          </ul>
        </Popover.Body>
      </Popover>
    );
  };

  isSelfContact = (contactElement) => {
    const { user } = this.props;
    const selfContact = contactElement.numbers.filter(
      (element) => element.number === user.number
    );
    return selfContact.length > 0 ? true : false;
  };

  isContactAlreadyOnACall = (contactElement) => {
    const { numbersInCalls } = this.props;
    const isOnCall = contactElement.numbers.filter((element) =>
      numbersInCalls.includes(element.number)
    );
    return isOnCall.length > 0 ? true : false;
  };

  render() {
    const {
      contactList,
      isApiFetching,
      target,
      shouldDisplayPopup,
      currentContactElement,
      processingAddOrTransferParticipant
    } = this.state;
    const { handleDialer, actionType, shouldDisableButton } = this.props;
    return (
      <div className="secondaryParticipant">
        <div className="mainboxheader">
          <div className="popupboxclose" onClick={() => handleDialer()}>
            <span>&#x00d7;</span>
          </div>
          <div className="contactuslist">
            <h4>Contacts</h4>
          </div>
        </div>
        <div className="searchboxsec">
          <input
            type="text"
            name="contactSearchInput"
            id="contactSearchInput"
            placeholder="Search Contacts"
            onChange={(e) => this.searchContact(e)}
            onKeyDown={() => {
              const { timerWillStart } = this.state;
              clearTimeout(timerWillStart);
            }}
          />
          <img
            alt=""
            src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${searchicon}`}
          />
        </div>
        <div className="phonebooklist phonebooklist">
          {isApiFetching && (
            <img
              alt=""
              className="logingicon"
              src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`}
            />
          )}
          {!isApiFetching &&
            contactList.length > 0 &&
            contactList.map((contactElement, index) => {
              return (
                <div className="phonebookbox" key={index}>
                  <div className="firsttext">
                    <div className="Nameletters">
                      <span>
                        {displayContactInitial(
                          contactElement.name === null
                            ? contactElement.numbers[0].number
                            : contactElement.name
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="phonebooknum">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={
                        contactElement.name === null
                          ? contactElement.numbers[0].number
                          : contactElement.name
                      }
                    >
                      {contactElement.name === null
                        ? contactElement.numbers[0].number
                        : contactElement.name}
                    </span>
                    <span className={`phonebook${contactElement.type}`}>
                      {contactElement.type}
                    </span>
                    <span>{contactElement.numbers[0].number}</span>
                  </div>

                  <div className="phonebookicon">
                    <Button
                      id={contactElement.numbers[0].number}
                      className="removecolorbut smallcallicon"
                      onClick={(e) =>
                        this.prepareItemAction(e, contactElement, "sms")
                      }
                      disabled={
                        actionType === "call" ||
                        actionType === "transfer" ||
                        processingAddOrTransferParticipant ||
                        this.isSelfContact(contactElement)
                      }
                    >
                      <img
                        alt=""
                        src={`${
                          shouldDisableButton
                            ? `${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`
                            : `${REACT_APP_STATIC_ASSETS_BASE_URL}${smallmessageicon}`
                        }`}
                      />
                    </Button>

                    <Button
                      id={contactElement.numbers[0].number}
                      className="removecolorbut smallcallicon"
                      onClick={(e) =>
                        this.prepareItemAction(e, contactElement, actionType)
                      }
                      disabled={
                        actionType === "conversation" ||
                        (actionType === "" && this.activeCallCount > 0) ||
                        processingAddOrTransferParticipant ||
                        this.isSelfContact(contactElement) ||
                        (actionType === "transfer" &&
                          contactElement.type.toLowerCase() !== "user") ||
                        this.isContactAlreadyOnACall(contactElement)
                      }
                    >
                      <img
                        alt=""
                        src={`${
                          shouldDisableButton
                            ? `${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`
                            : `${REACT_APP_STATIC_ASSETS_BASE_URL}${smallcallicon}`
                        }`}
                      />
                    </Button>
                  </div>
                </div>
              );
            })}
          {shouldDisplayPopup && (
            <Overlay
              show={shouldDisplayPopup}
              target={target}
              placement="bottom"
              containerPadding={20}
            >
              {this.popover(currentContactElement)}
            </Overlay>
          )}

          {!isApiFetching && contactList.length === 0 && (
            <div className="noresult ">No records found</div>
          )}
        </div>
      </div>
    );
  }
}

export default ContactList;
