import React, { createRef } from "react";
import { SyncClient } from "twilio-sync";
import { Button, Popover, Overlay } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Dialer from "../Dialer/Dialer";
import {
  getDeviceAccessToken,
  requestToResetDialer
} from "../Dialer/assets/library/api";
import { SECONDARY_DEVICE_INFO_MESSAGE } from "../Dialer/assets/library/constant";
import whitecallicon from "../CallButton/assets/images/bigmaincallicon.svg";
import loadingGif from "../CallButton/assets/images/loading-gif.gif";
import exclamation from "../CallButton/assets/images/exclamation.svg";
import outgoingcallicon from "../CallButton/assets/images/bigmainoutgoingcall.svg";
import inboundicon from "../CallButton/assets/images/bigmainincomingcall.svg";
import smallpauseicon from "../CallButton/assets/images/bigmainholdicon.svg";
import reseticon from "../CallButton/assets/images/reset.svg";
import confirm from "../CallButton/assets/images/confirm.svg";
import cancel from "../CallButton/assets/images/cancel.svg";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class CallButton extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      showCallListContainer: false,
      user: user,
      isTwilioDeviceInitialized: false,
      shouldDisplayPopup: false,
      isTwilioDeviceInitializationMessage: "",
      deviceAccessToken: null,
      chatAccessToken: null,
      syncAccessToken: null,
      callLogAccessToken: null,
      deviceId: null,
      sync_list_call_obj_template: null,
      syncClient: null,
      syncDocument: null,
      isPrimaryDevice: false,
      shouldDisplayRedDot: {
        calls: { count: 0, activeCallDirection: [], isAnyCallOnProgress: [] },
        messages: false,
        callLogs: false
      },
      reset: {
        message:
          "<p>Something went wrong. Please try again, if the issue persists please contact administrator</p>",
        status: 201,
        isResetting: false,
        isResetInitialize: false
      }
    };
    this.syncErrorRetryCount = 0;
    this.target = createRef(null);
    this.child = createRef(null);
    this.setAllUnreadCounts = this.setAllUnreadCounts.bind(this);
  }

  componentDidMount() {
    window.setAllUnreadCounts = (itemType, status, countableObject = false) => {
      this.setAllUnreadCounts(itemType, status, countableObject);
    };
    const { user } = this.state;
    if (
      user.number === "" ||
      user.name === "" ||
      user.tenant_id === "" ||
      user.subtenant_id === ""
    ) {
      this.updateDeviceMessage(
        "Something went wrong. Please check your console for more information or contact the technical support team."
      );
    } else {
      const { deviceAccessToken } = this.state;

      if (deviceAccessToken === null) {
        const getDeviceAccessTokenResponse = getDeviceAccessToken(
          user.number,
          user.tenant_id,
          user.subtenant_id
        );
        getDeviceAccessTokenResponse
          .then((accessTokenResponse) => {
            if (accessTokenResponse.status === 200) {
              this.setState(
                {
                  ...this.state,
                  deviceId: accessTokenResponse.data.device_id,
                  deviceAccessToken:
                    accessTokenResponse.data.device_access_token,
                  chatAccessToken: accessTokenResponse.data.chat_access_token,
                  syncAccessToken: accessTokenResponse.data.sync_access_token,
                  sync_list_call_obj_template:
                    accessTokenResponse.data.sync_list_call_obj_template,
                  callLogAccessToken:
                    accessTokenResponse.data.call_log_sync_access_token
                },
                () => {
                  const { setSdfcDetails } = this.props;

                  setSdfcDetails(accessTokenResponse.data.sfdc);
                  user.identity = accessTokenResponse.data.user.identity;
                  this.createSyncClient();
                }
              );
            } else {
              this.updateDeviceMessage(accessTokenResponse.data.message);
            }
          })
          .catch((e) => {
            this.updateDeviceMessage(
              "Unable to register your device due to authentication failure"
            );
          });
      }
    }
  }

  createSyncClient = () => {
    const { syncAccessToken } = this.state;

    const syncClient = new SyncClient(syncAccessToken);

    syncClient.on("connectionStateChanged", (newState) => {
      console.log("Received a new connection state:", newState);
      if (newState === "connected") {
        this.setState({ ...this.state, syncClient: syncClient }, () =>
          this.createOrOpenSyncDocument()
        );
      }
    });

    syncClient.on("connectionError", (connectionError) => {
      console.log("Connection was interrupted:", connectionError);
      if (this.child.current && this.child.current !== null) {
        this.child.current.destroyTwilioDevice();
      }
      const syncClientRetryingMsg =
        "A connection error occured, retrying in 10 seconds";
      this.updateDeviceMessage(syncClientRetryingMsg);
      if (syncClient) {
        syncClient.shutdown();
        if (this.syncErrorRetryCount < 18) {
          this.syncErrorRetryCount += 1;
          console.log(syncClientRetryingMsg);
          setTimeout(() => {
            this.createSyncClient();
          }, 10000);
        } else {
          this.updateDeviceMessage(
            "Could not reestablish the connection after several attempts. Please try reloading the page."
          );
        }
      }
    });
  };

  updateSyncClient = (syncAccessToken) => {
    const { syncClient } = this.state;
    syncClient.updateToken(syncAccessToken);
    this.setState({ ...this.state, syncAccessToken }, () =>
      this.createOrOpenSyncDocument()
    );
  };

  createOrOpenSyncDocument = () => {
    const { user } = this.props;
    const { syncClient } = this.state;
    syncClient
      .document(user.identity)
      .then((document) => {
        console.log("Successfully opened a document. SID:", document.sid);

        document.on("removed", (args) => {
          document.close();
          if (syncClient) {
            this.setState(
              {
                ...this.state,
                reset: {
                  ...this.state,
                  status: 201,
                  isResetting: false,
                  isResetInitialize: false
                },
                shouldDisplayRedDot: {
                  ...this.state.shouldDisplayRedDot,
                  calls: {
                    count: 0,
                    activeCallDirection: [],
                    isAnyCallOnProgress: []
                  }
                }
              },
              () => {
                if (this.child.current && this.child.current !== null) {
                  this.child.current.alterMenu(4);
                }
              }
            );
            this.createOrOpenSyncDocument();
          }
        });

        this.setState({ ...this.state, syncDocument: document }, async () => {
          const { syncDocument, deviceId } = this.state;
          syncDocument.on("updated", (event) => {
            console.log('Received an "updated" event: ', event);
            if (
              event.data.primaryDeviceId === deviceId ||
              event.data.primaryDeviceId === null
            ) {
              this.setState({
                ...this.state,
                isPrimaryDevice: true,
                isTwilioDeviceInitializationMessage: "",
                shouldDisplayPopup: false,
                reset: {
                  ...this.state.reset,
                  isResetting: false,
                  isResetInitialize: false,
                  status: 201
                },
                shouldDisplayRedDot: {
                  ...this.state.shouldDisplayRedDot,
                  calls: {
                    count: 0,
                    activeCallDirection: [],
                    isAnyCallOnProgress: []
                  }
                }
              });
            } else {
              if (this.child.current && this.child.current !== null) {
                this.child.current.destroyTwilioDevice();
              }

              this.updateDeviceMessage(SECONDARY_DEVICE_INFO_MESSAGE);
            }
          });
          if (
            syncDocument.revision === "0" ||
            syncDocument.data.primaryDeviceId === null
          ) {
            this.setState({
              ...this.state,
              isPrimaryDevice: true,
              isTwilioDeviceInitializationMessage: "",
              shouldDisplayPopup: false,
              reset: {
                ...this.state.reset,
                isResetting: false,
                isResetInitialize: false,
                status: 201
              }
            });
          } else {
            if (syncDocument.data.primaryDeviceId !== null) {
              this.updateDeviceMessage(SECONDARY_DEVICE_INFO_MESSAGE);
            }
          }
        });
      })
      .catch((error) => {
        console.error("Unexpected error", error);
      });
  };

  updateDeviceMessage = (msg) => {
    this.setState({
      ...this.state,
      isTwilioDeviceInitializationMessage: msg,
      isPrimaryDevice: false,
      isTwilioDeviceInitialized: false
    });
  };

  handleDialer = (keepOpen = false) => {
    const {
      showCallListContainer,
      isTwilioDeviceInitializationMessage,
      shouldDisplayPopup
    } = this.state;
    this.setState({
      ...this.state,
      showCallListContainer:
        !keepOpen && showCallListContainer === keepOpen
          ? !showCallListContainer
          : keepOpen,
      shouldDisplayPopup:
        isTwilioDeviceInitializationMessage === "" ? false : !shouldDisplayPopup
    });
    // this.makeChildOutGoingCall()
  };

  alterPopupDisplay = () => {
    const { shouldDisplayPopup } = this.state;
    this.setState({
      ...this.state,
      shouldDisplayPopup: !shouldDisplayPopup
    });
  };

  checkTwilioDeviceInitializeStatus = (currentStatus) => {
    this.setState({
      ...this.state,
      isTwilioDeviceInitialized: currentStatus,
      isTwilioDeviceInitializationMessage: "",
      shouldDisplayPopup: false
    });
  };

  initializeReset = () => {
    this.setState({
      ...this.state,
      reset: { ...this.state.reset, isResetInitialize: true }
    });
  };

  resetDialer = () => {
    const { user } = this.state;
    this.setState(
      { ...this.state, reset: { ...this.state.reset, isResetting: true } },
      async () => {
        try {
          const requestToResetDialerResponse = await requestToResetDialer(
            user.tenant_id,
            user.subtenant_id,
            user.number
          );
          this.setState({
            ...this.state,
            shouldDisplayPopup: false,
            reset: {
              ...this.state.reset,
              isResetInitialize: false,
              isResetting: false,
              status: requestToResetDialerResponse.status
            }
          });
        } catch (err) {
          this.setState({
            ...this.state,
            reset: {
              ...this.state.reset,
              status: 500
            }
          });
        }
      }
    );
  };

  cancelResetInitialization = () => {
    this.setState({
      ...this.state,
      reset: { ...this.state.reset, isResetInitialize: false }
    });
  };

  popover = () => {
    const { isTwilioDeviceInitializationMessage, reset } = this.state;
    return (
      <Popover
        id="popover-basic"
        className="d-flex flex-column align-items-center"
      >
        <Popover.Body className="d-flex flex-column justify-content-center align-items-center">
          {reset.isResetInitialize && !reset.isResetting && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p>Are you sure you want to reset all of your devices?</p>
              <p>
                Please note: If you proceed, calls on other devices will also
                get disconnected.
              </p>
              <div className="w-100 d-flex justify-content-evenly actionButtonContainer">
                <img src={confirm} alt="Confirm" onClick={this.resetDialer} />
                <img
                  src={cancel}
                  alt="Cancel"
                  onClick={this.cancelResetInitialization}
                />
              </div>
            </div>
          )}
          {reset.isResetInitialize && reset.isResetting && (
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <img className="w-25" src={loadingGif} alt="Resetting Dialer" />
              <p>Reset in progress....</p>
            </div>
          )}
        </Popover.Body>
        {!reset.isResetInitialize && !reset.isResetting && (
          <div className="d-flex flex-column justify-content-center align-items-center actionButtonContainer">
            {reset.status === 201 ? (
              <div
                className="initialErrorMsgContainer m-2"
                dangerouslySetInnerHTML={{
                  __html: isTwilioDeviceInitializationMessage
                }}
              ></div>
            ) : (
              <div
                className="initialErrorMsgContainer m-2"
                dangerouslySetInnerHTML={{
                  __html: reset.message
                }}
              ></div>
            )}
            <img
              src={reseticon}
              alt="Reset"
              className="mt-2 mb-2"
              onClick={this.initializeReset}
            />
          </div>
        )}
      </Popover>
    );
  };

  setAllUnreadCounts = (itemType, status, countableObject = false) => {
    const { shouldDisplayRedDot } = this.state;
    const countedObject = { ...shouldDisplayRedDot };
    if (itemType === "calls") {
      countedObject.calls.count = Object.values(countableObject).length;
      countedObject.calls.activeCallDirection = Object.values(
        countableObject
      ).filter((element) => element.conference.status === "in-progress");
      countedObject.calls.isAnyCallOnProgress = Object.values(
        countableObject
      ).filter(
        (element) =>
          !["initiated", "ringing"].includes(element.conference.status)
      );
    }
    if (itemType === "messages") {
      countedObject.messages = status;
    }
    if (itemType === "callLogs") {
      countedObject.callLogs = status;
    }
    this.setState({
      ...this.state,
      shouldDisplayRedDot: {
        ...this.state.shouldDisplayRedDot,
        ...countedObject
      }
    });
  };

  getNotificationIcon = () => {
    const { shouldDisplayRedDot } = this.state;
    if (shouldDisplayRedDot.calls.isAnyCallOnProgress.length > 0) {
      if (shouldDisplayRedDot.calls.activeCallDirection.length === 0) {
        return `${REACT_APP_STATIC_ASSETS_BASE_URL}${smallpauseicon}`;
      }
      if (
        shouldDisplayRedDot.calls.activeCallDirection[0].call.direction ===
        "outbound-api"
      ) {
        return `${REACT_APP_STATIC_ASSETS_BASE_URL}${outgoingcallicon}`;
      }

      if (
        shouldDisplayRedDot.calls.activeCallDirection[0].call.direction ===
        "inbound"
      ) {
        return `${REACT_APP_STATIC_ASSETS_BASE_URL}${inboundicon}`;
      }
    } else {
      return `${REACT_APP_STATIC_ASSETS_BASE_URL}${whitecallicon}`;
    }
  };

  shouldDisplayToastMessage = () => {
    toast("New Message Received !");
  };

  render() {
    const {
      showCallListContainer,
      isTwilioDeviceInitialized,
      isTwilioDeviceInitializationMessage,
      shouldDisplayPopup,
      deviceAccessToken,
      chatAccessToken,
      syncAccessToken,
      callLogAccessToken,
      deviceId,
      sync_list_call_obj_template,
      syncClient,
      isPrimaryDevice,
      syncDocument,
      shouldDisplayRedDot
    } = this.state;
    const { user } = this.state;
    const { resetUser } = this.props;
    return (
      <div className="rx-soft-phone">
        <div className="maincallicon">
          <Button
            className="removecolorbut maincallcalllist"
            onClick={() =>
              isTwilioDeviceInitialized
                ? this.handleDialer()
                : this.alterPopupDisplay()
            }
            id="callButton"
            ref={this.target}
          >
            {isTwilioDeviceInitialized ? (
              <img alt="" className="" src={`${this.getNotificationIcon()} `} />
            ) : isTwilioDeviceInitializationMessage !== "" ? (
              <img
                alt=""
                className="firstmaincallicon logingicon"
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${exclamation}`}
              />
            ) : (
              <img
                alt=""
                className="firstmaincallicon logingicon"
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`}
              />
            )}
            {(shouldDisplayRedDot.messages || shouldDisplayRedDot.callLogs) && (
              <span className="notificationdot"></span>
            )}
          </Button>
          {isPrimaryDevice && (
            <Dialer
              ref={this.child}
              user={user}
              checkTwilioDeviceInitializeStatus={
                this.checkTwilioDeviceInitializeStatus
              }
              showCallListContainer={showCallListContainer}
              handleDialer={this.handleDialer}
              deviceAccessToken={deviceAccessToken}
              chatAccessToken={chatAccessToken}
              syncAccessToken={syncAccessToken}
              callLogAccessToken={callLogAccessToken}
              deviceId={deviceId}
              sync_list_call_obj_template={sync_list_call_obj_template}
              syncClient={syncClient}
              syncDocument={syncDocument}
              updateDeviceMessage={this.updateDeviceMessage}
              shouldDisplayRedDot={shouldDisplayRedDot}
              updateSyncClient={this.updateSyncClient}
              shouldDisplayToastMessage={this.shouldDisplayToastMessage}
              resetUser={resetUser}
            />
          )}
        </div>
        {shouldDisplayPopup && (
          <Overlay
            show={shouldDisplayPopup}
            target={this.target.current}
            placement="top"
            containerPadding={20}
          >
            {this.popover()}
          </Overlay>
        )}
        <ToastContainer />
      </div>
    );
  }
}

export default CallButton;
