export const urls = {
  deviceAccessToken: {
    method: "POST",
    url: "/access-token"
  },
  getPreferences: {
    method: "POST",
    url: "/preferences"
  },
  updatePreferences: {
    method: "PUT",
    url: "/preferences"
  },
  outboundCallerId: {
    method: "PUT",
    url: "/user/primary-phone-number"
  },
  loggedinUserDetails: {
    method: "POST",
    url: "/logged-in-user"
  },
  resetDialerDetails: {
    method: "POST",
    url: "/sync/reset"
  }
};

export const SECONDARY_DEVICE_INFO_MESSAGE =
  "<p>It seems you have one or more active calls on another device or browser tab. Please complete all the calls there to start using this device.</p><p>If you are seeing this message due to an error, you can try resetting the device by clicking the reset button below.</p>";
