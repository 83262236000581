import React from "react";
import { Button } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { getLoggedinUser } from "./assets/service";
import CallButton from "../CallModule/CallButton/CallButton";
import Task from "../Task/Task";

import Logo from "../Task/assets/images/logo.png";

import { MsalContext } from '@azure/msal-react';
import { loginRequest } from './assets/service';
import './assets/login.css'

const { REACT_APP_GOOGLE_LOGIN_CLIENT_KEY } = process.env;

class Login extends React.Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      loggedInUser: false,
      isManuallyRefreshed: false,
      user,
      leads: [],
      sdfcDetails: {
        access_token: "",
        instance_url: "",
      },
      errMsg: "",
    };
  }

  componentDidMount() {
    this.createGoogleClient();
  }

  handleLogin = () => {
    const { instance } = this.context;
    instance.loginPopup(loginRequest).then(response => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const userAccount = accounts[0];
        const email = userAccount.username;
        this.fetchLeadsOfLoggedinUser(email);
      }
    }).catch(e => {
      console.error(e);
    });
  };
  

  handleLogout = () => {
    // const { instance } = this.context;
    // instance.logoutPopup().catch(e => {
    //   console.error(e);
    // });
    localStorage.removeItem("user");
    this.setState({ ...this.state, loggedInUser: false });
    setTimeout(() => {
      this.createGoogleClient();
    }, 2000);
  };
  handleCallBack = async (response) => {
    const userObj = await jwt_decode(response.credential);
    if (userObj.email_verified) {
      this.fetchLeadsOfLoggedinUser(userObj.email);
    }
  };

  fetchLeadsOfLoggedinUser = (email) => {
    return new Promise(async (resolve) => {
      const getLoggedinUserResponse = await getLoggedinUser(email);
      if (Object.keys(getLoggedinUserResponse.data.user).length > 0) {
        const tempUser = {
          id: getLoggedinUserResponse.data.user.id,
          name: getLoggedinUserResponse.data.user.name,
          number: getLoggedinUserResponse.data.user.phone_numbers.filter(
            (element) => element.is_primary === 1
          )[0]["number"],
          identity: getLoggedinUserResponse.data.user.user_identity,
          tenant_id: getLoggedinUserResponse.data.user.tenant_id,
          subtenant_id: getLoggedinUserResponse.data.user.subtenant_id,
          email_id: email,
          numbers: getLoggedinUserResponse.data.user.phone_numbers,
        };

        localStorage.setItem("user", JSON.stringify(tempUser));

        this.setState(
          {
            ...this.state,
            user: { ...tempUser },
            leads: [...getLoggedinUserResponse.data.leads],
            errMsg: "",
          },
          () => {
            this.setState({ ...this.state, loggedInUser: true }, () =>
              resolve(true)
            );
          }
        );
      } else {
        this.setState({
          ...this.state,
          errMsg: "Phone Number not found, Please contact your administrator!",
        });
      }
    });
  };

  createGoogleClient = () => {
    google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_LOGIN_CLIENT_KEY,
      callback: this.handleCallBack,
    });

    google.accounts.id.renderButton(document.getElementById("singleDiv"), {
      theme: "outline",
      size: "large",
    });
  };

  responseGoogle = (response) => {
    console.log(response);
  };

  setSdfcDetails = (sfdccInfo) => {
    this.setState({ ...this.state, sdfcDetails: { ...sfdccInfo } });
  };

  resetUser = (email) => {
    this.setState(
      { ...this.state, isManuallyRefreshed: true, loggedInUser: false },
      async () => {
        await this.fetchLeadsOfLoggedinUser(email);
        this.setState({ ...this.state, isManuallyRefreshed: false });
      }
    );
  };

  render() {
    const {
      loggedInUser,
      user,
      leads,
      sdfcDetails,
      isManuallyRefreshed,
      errMsg,
    } = this.state;
    return (
      <div>
        {!loggedInUser && !isManuallyRefreshed && (
          <div className="loginContainer">
            <div className="loginContainer loginItemContainer">
              <div className="row m-3 logoContainer">
                <img src={Logo} alt="" className="w-75" />
              </div>
              <div className="row m-5 logoContainer">
                <p>Regalix Contact Center</p>
              </div>
              <div id="singleDiv"></div>
              <Button className="mslogin" onClick={this.handleLogin}>Login with Microsoft</Button>
              {errMsg && (
                <span className="text-danger error-msg">{errMsg}</span>
              )}
            </div>
          </div>
        )}
        {loggedInUser && (
          <CallButton
            user={user}
            setSdfcDetails={this.setSdfcDetails}
            resetUser={this.resetUser}
          />
        )}
        {sdfcDetails.access_token !== "" && (
          <Task
            user={user}
            leads={leads}
            fetchLeadsOfLoggedinUser={this.fetchLeadsOfLoggedinUser}
            sdfcDetails={sdfcDetails}
          />
        )}
      </div>
    );
  }
}

export default Login;
