import axios from "axios";
import { urls } from "./constant";
const { REACT_APP_API_BASE_URL } = process.env;

const requestHeader = (
  apiEndPoint,
  method,
  formData = {},
  accessToken = false
) => {
  var config = {
    method: method,
    url: `${REACT_APP_API_BASE_URL}${apiEndPoint}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken
    },
    data: formData
  };

  return config;
};

export const getDeviceAccessToken = (number, tenant_id, subtenant_id) => {
  var config = requestHeader(
    urls.deviceAccessToken.url,
    urls.deviceAccessToken.method,
    { number, tenant_id, subtenant_id }
  );
  return axios(config);
};

export const getLoggedinUserDetails = (accessToken) => {
  var config = requestHeader(
    urls.loggedinUserDetails.url,
    urls.loggedinUserDetails.method,
    {},
    accessToken
  );
  return axios(config);
};

export const updatePreferences = (
  tenant_id,
  subtenant_id,
  number,
  payLoadObj
) => {
  var config = requestHeader(
    urls.updatePreferences.url,
    urls.updatePreferences.method,
    {
      tenant_id,
      subtenant_id,
      number,
      kwargs: { call_forwarding_rules: [...payLoadObj] }
    }
  );
  return axios(config);
};

export const getPreferences = (tenant_id, subtenant_id, number) => {
  var config = requestHeader(
    urls.getPreferences.url,
    urls.getPreferences.method,
    { tenant_id, subtenant_id, number }
  );
  return axios(config);
};

export const requestToResetDialer = (tenant_id, subtenant_id, number) => {
  var config = requestHeader(
    urls.resetDialerDetails.url,
    urls.resetDialerDetails.method,
    {
      tenant_id: tenant_id,
      subtenant_id: subtenant_id,
      reset: {
        conversations: false,
        call_sync_items: true,
        call_log_sync_items: false
      },
      phone_number: number
    }
  );
  return axios(config);
};

export const updateOutboundCallerId = (payLoadObj) => {
  var config = requestHeader(
    urls.outboundCallerId.url,
    urls.outboundCallerId.method,
    {
      ...payLoadObj
    }
  );
  return axios(config);
};
