export const urls = {
  uploadLeadFile: {
    url: "/leads/upload",
    method: "POST"
  },
  salesForceGetFeed: {
    method: "GET",
    url: "/services/apexrest/NoteDetails/"
  },
  salesForcePostFeed: {
    method: "POST",
    url: "/services/apexrest/NoteDetails/"
  }
};

export const barChartoptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    },
    title: {
      // display: true,
      // text: "Chart.js Bar Chart",
    }
  }
};

export const pieChartoptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    },
    title: {
      // display: true,
      // text: "Chart.js Bar Chart",
    }
  }
};

export const defaultBarChartData = {
  labels: [],
  datasets: [
    {
      label: "Total",
      data: [], // list of all total inside all leads
      backgroundColor: "#e61f4a"
    },
    {
      label: "Answer",
      data: [], // list of all answer inside all leads
      backgroundColor: "#33b2ea"
    }
  ]
};

export const sampleDataFrmAPI = {
  "+14158003518": {
    Total: 4,
    Answered: 4
  },
  "+15747667996": {
    Total: 2,
    Answered: 2
  }
};
