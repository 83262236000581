import React from "react";
import { displayContactInitial } from "../assets/helpers/helper";

const ContactInitial = ({ leadName, spanPadding }) => {
  return (
    <div
      className={`rounded-circle border border-info ${spanPadding} contactInitialIcon`}
    >
      {displayContactInitial(leadName)}
    </div>
  );
};

export default ContactInitial;
