import React from "react";
import { Button } from "react-bootstrap";
import EndConferenceButton from "./EndConferenceButton";
import backspace from "../assets/images/backspace.svg";
import callattend from "../assets/images/callicon.svg";

const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class KeyPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pressedKey: "",
      pressTimer: null,
      keyPressStartTime: 0,
      keyPressEndTime: 0
    };
  }

  componentDidMount() {
    document.onpaste = this.onPasteEvent;
  }

  onPasteEvent = async () => {
    const regexAllowedChars = /^[\d\(\)\+\*\#\-\ ]+$/;
    const clipboardData = await navigator.clipboard.readText();
    if (regexAllowedChars.test(clipboardData)) {
      this.setState({ ...this.state, pressedKey: clipboardData });
    }
  };

  sendDtmfTone = (e) => {
    const { innerHTML: textContent } = e.currentTarget.firstChild;
    const { currentConnection, callElement } = this.props;
    const { pressedKey } = this.state;
    this.setState(
      { ...this.state, pressedKey: `${pressedKey}${textContent}` },
      () => callElement && currentConnection.sendDigits(textContent)
    );
  };

  clearKeys = () => {
    const { pressedKey } = this.state;
    const xtractedPressedKey = pressedKey.split("");
    xtractedPressedKey.pop();
    this.setState({
      ...this.state,
      pressedKey: `${xtractedPressedKey.join("")}`
    });
  };

  onKeyDownEvent = async (e) => {
    const { key } = e;
    const regexAllowedChars = /^[\d\(\)\+\*\#\-\ ]+$/;
    if (key.toLowerCase() === "backspace") {
      this.clearKeys();
      return true;
    }
    if (regexAllowedChars.test(key)) {
      this.sendDtmfTone({ currentTarget: { firstChild: { innerHTML: key } } });
    }
  };

  startPress = () => {
    const { pressTimer, keyPressStartTime, keyPressEndTime } = this.state;

    if (keyPressEndTime - keyPressStartTime < 0) {
      this.sendDtmfTone({ currentTarget: { firstChild: { innerHTML: "0" } } });
    }
    clearTimeout(pressTimer);
  };

  startPressTimer = () => {
    this.state.keyPressStartTime = Date.now();

    const pressTimer = setTimeout(() => {
      this.state.keyPressEndTime = Date.now();
      this.sendDtmfTone({
        currentTarget: { firstChild: { innerHTML: "+" } }
      });
    }, 500);
    this.setState({ ...this.state, pressTimer });
  };

  render() {
    const {
      toggleKeypad,
      callElement,
      currentConnection,
      user,
      shouldDisableButton,
      alterButtonDiableProperty
    } = this.props;
    const { pressedKey } = this.state;
    return (
      <div
        className="keypadfullsec"
        onKeyDown={this.onKeyDownEvent}
        tabIndex={-1}
      >
        <div className="keypadnumberinputbox">
          <input
            type="text"
            name="keyNumber"
            id="keyNumber"
            disabled
            value={pressedKey}
          />
          {pressedKey !== "" && (
            <Button
              className="removecolorbut backspace"
              onClick={() => this.clearKeys()}
              disabled={shouldDisableButton}
            >
              <img
                alt=""
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${backspace}`}
              />
            </Button>
          )}
        </div>
        <div className="grid-container ">
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>1</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>2</span>
            <span>A B C</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>3</span>
            <span>D E F</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>4</span>
            <span>G H I</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>5</span>
            <span>J K L</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>6</span>
            <span>M N O</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>7</span>
            <span>P Q R S</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>8</span>
            <span>T U V</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>9</span>
            <span>W X Y Z</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>*</span>
          </div>
          <div
            className="grid-item"
            onMouseUp={(e) => this.startPress(e)}
            onMouseDown={(e) => this.startPressTimer(e)}
          >
            <span>0</span>
            <span>+</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>#</span>
          </div>
        </div>
        {callElement && (
          <div className="keypadendcallsec">
            <EndConferenceButton
              callElement={callElement}
              currentConnection={currentConnection}
              user={user}
              conferenaceSid={callElement.conference.sid}
              shouldDisableButton={shouldDisableButton}
              alterButtonDiableProperty={alterButtonDiableProperty}
            />
            <Button
              className="removecolorbut hideicon"
              onClick={() => toggleKeypad()}
            >
              Hide
            </Button>
          </div>
        )}
        {!callElement && (
          <img
            alt=""
            className="hand-pointer rounded-circle w-100 makeCallBtn"
            src={`${callattend}`}
            onClick={() => {
              pressedKey !== "" &&
                window.makeOutBoundCall(pressedKey, pressedKey);
            }}
          />
        )}
      </div>
    );
  }
}

export default KeyPad;
